import React from "react"

import Grid from "@ecom/ui/components/Grid"
import Container from "@ecom/ui/components/Container"
import { FirstCard, SecondCard } from "./img"
import * as styles from "./otherCards.module.scss"
import { SecondCardBG } from "./img/SecondCardBG"

interface IOtherCards {
  title?: string
  firstCardText?: string
  secondCardText?: string
  orderNum?: string
}

const OtherCards = ({
  title = "Рефинансируйте другие кредитные карты",
  firstCardText = "Переведите долги по кредитным картам до 300 000 ₽ на Халву",
  secondCardText = "Наслаждайтесь рассрочкой 24 месяца",
  orderNum,
}: IOtherCards) => (
  <section className={styles.otherCards} data-exclude={orderNum}>
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={10} sm={8} md={5}>
          <h2>{title}</h2>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={styles.cardsBlock}>
        <Grid className={styles.firstCard} item xs={12} sm={6}>
          {firstCardText}
          <FirstCard alt="карта Совкомбанк" className={styles.firstImage} />
        </Grid>
        <Grid className={styles.secondCard} item xs={12} sm={6}>
          {secondCardText}
          <SecondCardBG alt="" className={styles.secondBG} />
          <SecondCard alt="карта Совкомбанк" className={styles.secondImage} />
        </Grid>
      </Grid>
    </Container>
  </section>
)

export default OtherCards
